<template>
  <div>
    <img src="@/assets/logo.svg" alt="">
    <h2>Передача и обработка данных.</h2>
  </div>
  <el-button-group style="margin-bottom: 24px">
    <el-button type="primary" @click="tab = 'sync'">Синхронизации с CRM</el-button>
    <el-button type="primary" @click="tab = 'file'">Обработка файла</el-button>
  </el-button-group>
  <SyncForm v-if="tab === 'sync'" />
  <FileForm v-if="tab === 'file'" />
</template>

<script>
import SyncForm from "@/components/SyncForm.vue";
import FileForm from "@/components/FileForm.vue";

export default {
  name: 'App',
  components: {
    FileForm,
    SyncForm,
  },
  data() {
    return {
      tab: "sync"
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 800px;
  margin: 20px auto;
}
</style>

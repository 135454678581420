<template>
  <el-upload
      ref="upload"
      action=""
      :http-request="uploadFile"
      :auto-upload="false"
      :show-file-list="false"
      style="display: flex; align-items: center; justify-content: center"
  >
    <template #trigger>
      <el-button type="primary" style="margin-right: 12px">Выбрать файл</el-button>
    </template>
    <el-button class="ml-3" type="success" @click="submitUpload">
      Загрузить
    </el-button>
  </el-upload>
</template>

<script>
import axios from "axios";

export default {
  name: 'FileForm',
  methods: {
    async uploadFile({file}) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post('https://api.rentmate.kz/admin/api/sync/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'result.txt');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error uploading the file:', error);
      }
    },
    submitUpload() {
      this.$refs.upload.submit();
    }
  }
}
</script>

<style scoped>

</style>
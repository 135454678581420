<template>
  <el-input v-model="api" placeholder="URL" style="margin-bottom: 16px" />
  <el-button-group>
    <a href="https://api.rentmate.kz/admin/api/sync/companies" class="el-button el-button--primary" style="text-decoration: none">Передать контрагентов</a>
    <a href="https://api.rentmate.kz/admin/api/sync/contracts" class="el-button el-button--primary" style="text-decoration: none">Передать контракты</a>
    <a href="https://api.rentmate.kz/admin/api/sync/payments" class="el-button el-button--primary" style="text-decoration: none">Передать оплаты</a>
  </el-button-group>
</template>

<script>
export default {
  name: 'SyncForm',
  data() {
    return {
      api: null,
    }
  },
}
</script>

<style scoped>

</style>